import { Group, Image, Title } from '@mantine/core'

const TicknaskLogo = () => {
  return (
    <Group>
      <Image width={30} src={"logo.png"} />
      <Title order={3} size="h2">
        Ticknask
      </Title>
    </Group>
  )
}

export default TicknaskLogo