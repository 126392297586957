import React, { Fragment } from 'react'
import { FaqSimple } from '../Components/FAQ';
import { HeroBullets } from '../Components/Top';
import { GetInTouch } from '../Components/Contact';
import { FeaturesGrid } from '../Components/Features';
import { FeaturesImages } from '../Components/ForWho';
import { ActionsGrid } from '../Components/Stats';

const Home = () => {
  return (
    <Fragment>
      <HeroBullets />
      <FeaturesGrid title={"Hello"} description={"Hello"}/>
      <ActionsGrid />
      <FeaturesImages supTitle={"HElo"} description={undefined} data={[
      {
        "image": "https://img.icons8.com/?size=512&id=38833&format=png",
        "title": "Students",
        "description": "Enhance your learning experience, improve study habits, and stay organized throughout your academics."
      },
      {
        "image": "https://img.icons8.com/?size=512&id=53381&format=png",
        "title": "Developers",
        "description": "Enhance various aspects of your workflow, improve productivity, and organize your ideas and code snippets. "
      },
      {
        "image": "https://img.icons8.com/?size=512&id=2563&format=png",
        "title": "Writers",
        "description": "Streamline your writing process, improve productivity, and organize your ideas and notes. "
      },
      {
        "image": "https://img.icons8.com/?size=512&id=BaR6TcAacGyU&format=png",
        "title": "Unemployed like me",
        "description": "Organize your job search, improve productivity, and keep track of your applications. "
      }
    ]}  />
      <FaqSimple />
      <GetInTouch />
    </Fragment>
  )
}

export default Home