export const pricingData = {
    basic:{
        price:{
            monthly: 0,
            yearly: 0
        },
        features:[
            "Notes - 100",
            "Nist - 20",
            "File  - 100 MB",
            "Device Allowed - 1",
            "Tick101 7 Day Trial",
            "Email Support within 1 week",
        ]
    },
    professional:{
        price:{
            monthly: 10,
            yearly: 100
        },
        features:[
            "Notes - 1000",
            "Nist - 100",
            "File Storage - 1 GB",
            "Device Allowed - 5",
            "GPT 3.5 Support",
            "Tick101 Features",
            "Email Support within 1 day",
            "Beta features",
            "All Basic Features",
        ]
    },
    master:{
        price:{
            monthly: 50,
            yearly: 500
        },
        features:[
            "Notes - Unlimited",
            "Nist - Unlimited",
            "File Storage - Unlimited",
            "Dalle Support",
            "Device Allowed - Unlimited",
            "Email Support within 1 hour",
            "All Professional Features"
        ]
    }
}