import React from 'react'
import { Route, Routes as Switch} from 'react-router-dom'
import Home from '../Pages/Home'
import Pricing from '../Pages/Pricing'
import { DownloadTop } from '../Pages/Download'

const Routes = () => {
  return (
    <Switch>
      <Route path="/" element={<Home />} />
      <Route path="/pricing" element={<Pricing />} />
      <Route path="/downloads" element={<DownloadTop />} />
    </Switch>
  )
}

export default Routes