import { Box, MantineProvider, Text } from '@mantine/core';
import { HeaderResponsive } from './Components/Header';
import { FooterLinks } from './Components/Footer';
import { BrowserRouter, Link } from 'react-router-dom';
import Routes from './Routes/Routes';
import { useEffect, useState } from 'react';

function App() {
	const [data, setdata] = useState({
		"text": "",
		"link": ""
	})

	useEffect(() => {
		// store ref from window.location.query to localStorage
		const urlParams = new URLSearchParams(window.location.search);
		const ref = urlParams.get('ref');
		if (ref) {
			localStorage.setItem('ref', ref);
			window.location.search = "";
		}

	}, [])

	useEffect(() => {
		fetch('https://ticknask-backend-ten.vercel.app/space/banner')
			.then(response => response.json())
			.then(data => setdata(data));
	}, [])

	return (
		<MantineProvider withGlobalStyles withNormalizeCSS
			theme={{
				colorScheme: 'dark',
			}}
		>
			<BrowserRouter>
				{
					data?.text && data?.link && (
						<Box
							// component={Link} to={data?.link}
							onClick={()=>{
								window.open(data?.link)
							}}
							sx={(theme) => ({
								backgroundColor: theme.colorScheme === 'dark' ? 'rgb(250 245 148)' : theme.colors.gray[0],
								color: theme.colorScheme === 'dark' ? theme.colors.gray[9] : theme.colors.gray[9],
								textAlign: 'center',
								fontWeight: 700,
								padding: theme.spacing.xs,
								paddingLeft: theme.spacing.xl,
								paddingRight: theme.spacing.xl,
								cursor: 'pointer',
							})}
						>
							{data?.text}
						</Box>
					)
				}
				<HeaderResponsive links={[
					{ label: 'Home', link: '/' },
					{ label: 'Features', link: '/features' },
					{ label: 'Downloads', link: '/downloads' },
					{ label: 'Pricing', link: '/pricing' },
					{ label: 'About', link: '/about' },
					{ label: 'Contact', link: '/contact' },
				]} />
				<Routes />
				<FooterLinks data={
					[
						{
							"title": "About",
							"links": [
								{ "label": "Features", "link": "#" },
								{ "label": "Pricing", "link": "#" },
								{ "label": "Support", "link": "#" },
								{ "label": "Forums", "link": "#" }
							]
						},
						{
							"title": "Project",
							"links": [
								{ "label": "Contribute", "link": "#" },
								{ "label": "Media assets", "link": "#" },
								{ "label": "Changelog", "link": "#" },
								{ "label": "Releases", "link": "#" }
							]
						},
						{
							"title": "Community",
							"links": [
								{ "label": "Join Discord", "link": "https://discord.gg/DmGPWg5C" },
								{ "label": "Follow on Twitter", "link": "#" },
								{ "label": "Email newsletter", "link": "#" },
								{ "label": "GitHub discussions", "link": "#" }
							]
						}
					]
				} />
			</BrowserRouter>

		</MantineProvider>
	);
}

export default App;