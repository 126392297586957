import { createStyles, Container, Text, Button, Group, rem, Flex } from '@mantine/core';
import { DownloadCard } from '../Components/DownloadCar';
import { openVercel } from '../Hooks/RedirectLink';

const useStyles = createStyles((theme) => ({
    wrapper: {
        position: 'relative',
        boxSizing: 'border-box',
        backgroundColor: theme.colorScheme === 'dark' ? "#1A1B1E" : theme.white,
    },

    inner: {
        position: 'relative',
        paddingTop: rem(140),
        paddingBottom: rem(120),

        [theme.fn.smallerThan('sm')]: {
            paddingBottom: rem(80),
            paddingTop: rem(80),
        },
    },

    title: {
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        fontSize: rem(62),
        fontWeight: 900,
        lineHeight: 1.1,
        margin: 0,
        padding: 0,
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,

        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(42),
            lineHeight: 1.2,
        },
    },

    description: {
        marginTop: theme.spacing.xl,
        fontSize: rem(24),

        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(18),
        },
    },

    controls: {
        marginTop: `calc(${theme.spacing.xl} * 2)`,

        [theme.fn.smallerThan('sm')]: {
            marginTop: theme.spacing.xl,
        },
    },

    control: {
        height: rem(54),
        paddingLeft: rem(38),
        paddingRight: rem(38),

        [theme.fn.smallerThan('sm')]: {
            height: rem(54),
            paddingLeft: rem(18),
            paddingRight: rem(18),
            flex: 1,
        },
    },
}));

export function DownloadTop() {
    const { classes } = useStyles();

    const platforms = [
        {
            pmName: "Windows",
            pmImg: "https://img.icons8.com/?size=512&id=OlmjyQ9zkRzF&format=png",
            pmRequirements: "Windows 10 or later",
            pmLink: "https://github.com/nalaso/ticknask-note/releases/download/0.1.0-beta/ticknask-desktop_0.1.0-beta-win-installer.exe"
        },
        {
            pmName: "Linux",
            pmImg: "https://img.icons8.com/?size=480&id=38796&format=png",
            pmRequirements: "Linux 10 or later",
            pmLink: "https://github.com/nalaso/ticknask-note/releases/download/0.1.0-beta/ticknask-desktop_0.1.0-beta-win-installer.exe"
        },
        {
            pmName: "MacOS",
            pmImg: "https://img.icons8.com/?size=512&id=119774&format=png",
            pmRequirements: "MacOS 10 or later",
            pmLink: "https://github.com/nalaso/ticknask-note/releases/download/0.1.0-beta/ticknask-desktop-0.1.0-beta-arm64.dmg"
        },
        {
            pmName: "Android",
            pmImg: "https://img.icons8.com/?size=512&id=99273&format=png",
            pmRequirements: "Android 10 or later",
        },
        {
            pmName: "iOS",
            pmImg: "https://img.icons8.com/?size=512&id=20828&format=png",
            pmRequirements: "iOS 10 or later",
        },
        {
            pmName: "Web",
            pmImg: "https://img.icons8.com/?size=512&id=123437&format=png",
            pmRequirements: "Any modern browser",
        },
    ]

    return (
        <div className={classes.wrapper}>
            <Container className={classes.inner}>
                <h1 className={classes.title}>
                    <Text component="span" variant="gradient" gradient={{ from: '#4772fa', to: '#4772fa' }} inherit>
                        Ticknask
                    </Text>{' : '}
                    Your Task Management Companion Across All Platforms!
                </h1>

                <Text className={classes.description} color="dimmed">
                    TickNask is accessible and seamlessly integrated across popular platforms like Windows, macOS, Android, iOS, and web browsers, ensuring you can manage your tasks from any device with ease.
                </Text>

                <Group className={classes.controls}>
                    <Button
                        size="xl"
                        className={classes.control}
                        variant="gradient"
                        gradient={{ from: '#4772fa', to: '#003dffcc' }}
                        onClick={() => openVercel()}
                    >
                        Continue Web
                    </Button>

                    <Button
                        component="a"
                        href="https://github.com/mantinedev/mantine"
                        size="xl"
                        variant="default"
                        className={classes.control}
                        onClick={()=>window.open("https://github.com/nalaso/ticknask-note/releases")}
                    >
                        Need Help?
                    </Button>
                </Group>
            </Container>
            <Container className={classes.inner}>
                <Flex
                    mih={50}
                    bg="rgba(0, 0, 0, .3)"
                    gap="md"
                    justify="center"
                    align="center"
                    direction="row"
                    wrap="wrap"
                    style={{
                        backgroundColor: "#1A1B1E",
                    }}
                >
                    {
                        platforms.map((item, index) => (
                            <DownloadCard item={item} />
                        ))
                    }
                </Flex>
            </Container>
        </div>
    );
}