import { Container, createStyles, rem, Switch } from '@mantine/core'
import { Box, Button, Divider, Flex, Group, Stack, Text, Title, useMantineTheme } from '@mantine/core'
import React, { useState } from 'react'
import { pricingData } from '../Data/Pricing';
import { openVercel } from '../Hooks/RedirectLink';

const useStyles = createStyles((theme) => ({

    container:{
        [theme.fn.smallerThan('md')]: {
            justifyContent: 'center',
        },
    },

    inner: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: `calc(${theme.spacing.xl}) * 4`,
        paddingBottom: `calc(${theme.spacing.xl} * 4)`,
        // height: '80vh',
        [theme.fn.smallerThan('md')]: {
            justifyContent: 'center',

        },
    },

    content: {
        maxWidth: rem(480),
        marginRight: `calc(${theme.spacing.xl} * 3)`,

        [theme.fn.smallerThan('md')]: {
            maxWidth: '100%',
            marginRight: 0,
        },
    },

    title: {
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        fontSize: rem(44),
        lineHeight: 1.2,
        fontWeight: 900,

        [theme.fn.smallerThan('xs')]: {
            fontSize: rem(28),
        },
    },

    control: {
        [theme.fn.smallerThan('xs')]: {
            flex: 1,
        },
    },

    image: {
        flex: 1,

        [theme.fn.smallerThan('md')]: {
            display: 'none',
        },
    },

    highlight: {
        position: 'relative',
        backgroundColor: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).background,
        borderRadius: theme.radius.sm,
        padding: `${rem(4)} ${rem(12)}`,
    },
}));

const Pricing = () => {
    const theme = useMantineTheme()
    const [monthly, setMonthly] = useState(false)

    const [Basic, setBasic] = useState(pricingData.basic)
    const [Professional, setProfessional] = useState(pricingData.professional)
    const [Master, setMaster] = useState(pricingData.master)

    const handleChange = () => {
        setMonthly(!monthly)
    }
    const { classes } = useStyles();
    return (
        <Container mt={50} className={classes.container}>
            <div className={classes.inner}>
                <div className={classes.content}>
                    <Group
                        sx={{ zIndex: 50 }}
                    >
                        <Stack spacing={40} >
                            {/** header section */}
                            <Flex
                                direction='column'
                                gap={30}
                                align='center'
                                justify='start'
                            >
                                <Title
                                    order={2}
                                    color={theme.colorScheme === 'dark' ? 'white' : 'hsl(233, 13%, 49%)'}
                                >Our Pricing</Title>
                                <Box
                                    sx={{
                                        fontWeight: 700,
                                        color: theme.colorScheme === 'dark' ? theme.colors.dark[1] : 'hsl(234, 14%, 74%)',
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 19,
                                        marginBottom: '1rem'
                                    }}
                                >
                                    <Text fz={'sm'}>Annually</Text>
                                    <Switch
                                        checked={monthly}
                                        onChange={handleChange}
                                        width={45}
                                        height={25}
                                    />
                                    <Text fz={'sm'}>Monthly</Text>
                                </Box>
                            </Flex>
                            {/** cards section */}
                            <Group>
                                <Flex
                                    align={'center'}
                                    direction={{ base: 'column', sm: 'row' }}
                                    color={'hsl(232, 13%, 33%)'}
                                    gap={{ base: '1.5rem', sm: 0 }}
                                >
                                    <Box
                                        sx={{
                                            boxShadow: '0px 30px 50px -7px rgba(0,0,0,0.1)',
                                            // height: '22rem',
                                            padding: '1.5rem',
                                            width: '17rem',
                                            paddingInline: '1.5rem',
                                            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : 'white',
                                            borderRadius: '0.7rem 0 0 0.7rem',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',

                                            '@media (max-width: 755px)': {
                                                width: '19rem',
                                                borderRadius: '0.7rem',
                                            },
                                            '@media (min-width: 756px) and (max-width: 820px)': {
                                                width: '15rem',
                                                borderRadius: '0.7rem 0 0 0.7rem',
                                            },
                                        }}
                                    >
                                        <Stack w={'100%'} align={'center'} spacing={20}>
                                            <Text sx={{
                                                fontWeight: 700,
                                                color: theme.colorScheme === 'dark' ? theme.colors.dark[1] : 'hsl(233, 13%, 49%)',
                                            }} fz={'md'}>Basic</Text>
                                            <Title
                                                order={2}
                                                sx={{
                                                    color: theme.colorScheme === 'dark' ? 'white' : 'hsl(232, 13%, 33%)',
                                                    fontSize: 50,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: 5
                                                }}
                                            >
                                                <Text fz={'2rem'}>$</Text>
                                                {monthly ? Basic.price.monthly : Basic.price.yearly}
                                            </Title>
                                            <Stack w={'100%'} align='center' spacing={10} sx={{ color: theme.colorScheme === 'light' ? 'hsl(233, 13%, 49%)' : undefined }}>
                                                <Divider sx={{ width: '100%', borderColor: theme.colorScheme === 'dark' ? 'gray' : undefined, opacity: theme.colorScheme === 'dark' ? 0.7 : undefined }} />
                                                {
                                                    Basic.features.map((feature, index) => (
                                                        <>
                                                            <Text fz={'sm'} fw={600} >{feature}</Text>
                                                            <Divider sx={{ width: '100%', borderColor: theme.colorScheme === 'dark' ? 'gray' : undefined, opacity: theme.colorScheme === 'dark' ? 0.7 : undefined }} />
                                                        </>
                                                    ))
                                                }
                                            </Stack>
                                            <Button
                                                variant='gradient'
                                                gradient={{ from: '#4772fa', to: '#003dff' }}
                                                w='100%'
                                                onClick={()=>openVercel()}
                                            >
                                                Get Started
                                            </Button>
                                        </Stack>
                                    </Box>
                                    <Box
                                        sx={{
                                            boxShadow: '0px 30px 50px -7px rgba(0,0,0,0.1)',
                                            // height: '22rem',
                                            padding: '1.5rem',
                                            width: '19rem',
                                            paddingInline: '1.5rem',
                                            background: 'linear-gradient(to bottom right, #4772fa, #4772fa)',
                                            color: 'white',
                                            borderRadius: '0.7rem',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',

                                            '@media (min-width: 756px) and (max-width: 820px)': {
                                                width: '15rem',
                                                borderRadius: '0.7rem',
                                            },
                                        }}
                                    >
                                        <Stack w={'100%'} align={'center'} spacing={20}>
                                            <Text sx={{
                                                fontWeight: 700,
                                            }} fz={'md'}>Professional</Text>
                                            <Title
                                                order={2}
                                                sx={{
                                                    fontSize: 50,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: 5
                                                }}
                                            >
                                                <Text fz={'2rem'}>$</Text>
                                                {monthly ? Professional.price.monthly : Professional.price.yearly}
                                            </Title>
                                            <Stack w={'100%'} align='center' spacing={10}>
                                                <Divider sx={{ width: '100%', borderColor: theme.colorScheme === 'dark' ? 'gray' : undefined, opacity: theme.colorScheme === 'dark' ? 0.7 : undefined }} />
                                                {
                                                    Professional.features.map((feature, index) => (
                                                        <>
                                                            <Text fz={'sm'} fw={600} >{feature}</Text>
                                                            <Divider sx={{ width: '100%', borderColor: theme.colorScheme === 'dark' ? 'gray' : undefined, opacity: theme.colorScheme === 'dark' ? 0.7 : undefined }} />
                                                        </>
                                                    ))
                                                }
                                            </Stack>
                                            <Button
                                                sx={{
                                                    backgroundColor: 'white',
                                                    color: 'hsl(237, 63%, 64%)',

                                                    '&:hover': {
                                                        backgroundColor: 'white',
                                                        opacity: 0.95
                                                    }
                                                }}
                                                w='100%'
                                            >
                                                LEARN MORE
                                            </Button>
                                        </Stack>
                                    </Box>
                                    <Box
                                        sx={{
                                            boxShadow: '0px 30px 50px -7px rgba(0,0,0,0.1)',
                                            // height: '22rem',
                                            padding: '1.5rem',
                                            width: '18rem',
                                            paddingInline: '1.5rem',
                                            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : 'white',
                                            borderRadius: '0 0.7rem 0.7rem 0',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',

                                            '@media (max-width: 755px)': {
                                                width: '19rem',
                                                borderRadius: '0.7rem',
                                            },
                                            '@media (min-width: 756px) and (max-width: 820px)': {
                                                width: '15rem',
                                                borderRadius: '0 0.7rem 0.7rem 0',
                                            },
                                        }}
                                    >
                                        <Stack w={'100%'} align={'center'} spacing={20}>
                                            <Text sx={{
                                                fontWeight: 700,
                                                color: theme.colorScheme === 'dark' ? theme.colors.dark[1] : 'hsl(233, 13%, 49%)',
                                            }} fz={'md'}>Master</Text>
                                            <Title
                                                order={2}
                                                sx={{
                                                    color: theme.colorScheme === 'dark' ? 'white' : 'hsl(232, 13%, 33%)',
                                                    fontSize: 50,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: 5
                                                }}
                                            >
                                                <Text fz={'2rem'}>$</Text>
                                                {monthly ? Master.price.monthly : Master.price.yearly}
                                            </Title>
                                            <Stack w={'100%'} align='center' spacing={10} sx={{ color: theme.colorScheme === 'light' ? 'hsl(233, 13%, 49%)' : undefined }}>
                                                <Divider sx={{ width: '100%', borderColor: theme.colorScheme === 'dark' ? 'gray' : undefined, opacity: theme.colorScheme === 'dark' ? 0.7 : undefined }} />
                                                {
                                                    Master.features.map((feature, index) => (
                                                        <>
                                                            <Text fz={'sm'} fw={600} >{feature}</Text>
                                                            <Divider sx={{ width: '100%', borderColor: theme.colorScheme === 'dark' ? 'gray' : undefined, opacity: theme.colorScheme === 'dark' ? 0.7 : undefined }} />
                                                        </>
                                                    ))
                                                }
                                            </Stack>
                                            <Button
                                                variant='gradient'
                                                gradient={{ from: '#4772fa', to: '#003dff' }}
                                                w='100%'
                                            >
                                                Contact Us
                                            </Button>
                                        </Stack>

                                    </Box>
                                </Flex>
                            </Group>
                        </Stack>
                    </Group>
                </div>
            </div>
        </Container>

    )
}

export default Pricing