import { ThemeIcon, Text, Title, Container, SimpleGrid, createStyles, rem } from '@mantine/core';
// import { IconGauge, IconCookie, IconUser, IconMessage2, IconLock } from '@tabler/icons-react';

export const MOCKDATA = [
  {
    //icon: //iconGauge,
    title: 'Extreme performance',
    description:
      'Prepare to have your productivity skyrocket and your note-taking experience reach unparalleled heights.',
  },
  {
    //icon: //iconUser,
    title: 'Privacy focused',
    description:
    "We've crafted a note-taking experience that prioritizes confidentiality without compromising on functionality or convenience",
  },
  {
    //icon: //iconCookie,
    title: 'No third parties',
    description:
      "We are completely free from third-party involvement. With us, you can experience pure note-taking without any external interference or data sharing.",
  },
  {
    //icon: //iconLock,
    title: 'Collboration',
    description:
      "Whether you're working on a project with colleagues, studying with classmates, or planning events with friends, CollaboraNote is your ultimate companion for real-time collaboration and idea sharing.",
  },
  {
    //icon: //iconMessage2,
    title: 'Encryption',
    description:
      "With Ticknask, you can rest assured that your notes are protected with the highest level of encryption, ensuring that only you have access to your private data.",
  },
  {
    //icon: //iconMessage2,
    title: '24/7 Support',
    description:
      "We goes above and beyond to provide you with unparalleled support. Our commitment to delivering exceptional service means you can count on us 24/7 for any assistance you may need.",
  },
];

interface FeatureProps {
//   icon: React.FC<any>;
  title: React.ReactNode;
  description: React.ReactNode;
}

export function Feature({  title, description }: FeatureProps) {
  return (
    <div style={{
      // border:"1px solid #ffffff99",
      padding:20,
      paddingTop:0,
      borderRadius: 8,
      backgroundColor:"#25262b",
      boxShadow: "0 0 1px 0 rgb(8 11 14 / 6%), 0 16px 16px -1px rgb(8 11 14 / 10%)"
    }}>
      {/* <ThemeIcon variant="light" size={40} radius={40}>
        <Icon size="1.1rem" stroke={1.5} />
      </ThemeIcon> */}
      <Text weight={800} size={18} mt="sm" mb={7}>
        {title}
      </Text>
      <Text size="sm" color="dimmed" sx={{ lineHeight: 1.6 }}>
        {description}
      </Text>
    </div>
  );
}

const useStyles = createStyles((theme) => ({
  wrapper: {
    paddingTop: `calc(${theme.spacing.xl} * 4)`,
    paddingBottom: `calc(${theme.spacing.xl} * 4)`,
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontWeight: 900,
    marginBottom: theme.spacing.md,
    textAlign: 'center',

    [theme.fn.smallerThan('sm')]: {
      fontSize: rem(28),
      textAlign: 'left',
    },
  },

  description: {
    textAlign: 'center',

    [theme.fn.smallerThan('sm')]: {
      textAlign: 'left',
    },
  },
}));

interface FeaturesGridProps {
  title: React.ReactNode;
  description: React.ReactNode;
  data?: FeatureProps[];
}

export function FeaturesGrid({ title, description, data = MOCKDATA }: FeaturesGridProps) {
  const { classes } = useStyles();
  const features = data.map((feature, index) => <Feature {...feature} key={index} />);

  return (
    <Container className={classes.wrapper}>
      <Title className={classes.title}>{"Ticknask: your note making companion"}</Title>

      <Container size={560} p={0}>
        <Text size="sm" className={classes.description}>
          {/* {description} */}
        </Text>
      </Container>

      <SimpleGrid
        mt={60}
        cols={3}
        spacing={50}
        breakpoints={[
          { maxWidth: 980, cols: 2, spacing: 'xl' },
          { maxWidth: 755, cols: 1, spacing: 'xl' },
        ]}
      >
        {features}
      </SimpleGrid>
    </Container>
  );
}