import { Card, Image, Text, Group, Badge, createStyles, Center, Button, rem } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor: "#2C2E33",
    width: 290,
  },

  imageSection: {
    padding: theme.spacing.md,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // backgroundColor:'#ffffff8c',
    borderBottom: `${rem(1)} solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },

  label: {
    marginBottom: theme.spacing.xs,
    lineHeight: 1,
    fontWeight: 700,
    fontSize: theme.fontSizes.xs,
    letterSpacing: rem(-0.25),
    textTransform: 'uppercase',
  },

  section: {
    padding: theme.spacing.md,
    borderTop: `${rem(1)} solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },

  icon: {
    marginRight: rem(5),
    color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[5],
  },
}));

const mockdata = [
  { label: '4 passengers', icon: null },
  { label: '100 km/h in 4 seconds', icon: null },
  { label: 'Automatic gearbox', icon: null },
  { label: 'Electric', icon: null },
];

export function DownloadCard({ item }:any) {
  const { classes } = useStyles();
  const features = mockdata.map((feature) => (
    <Center key={feature.label}>
      {/* <feature.icon size="1.05rem" className={classes.icon} stroke={1.5} /> */}
      <Text size="xs">{feature.label}</Text>
    </Center>
  ));

  return (
    <Card withBorder radius="md" className={classes.card}>
      <Card.Section className={classes.imageSection}>
        <Image style={{
            filter: "invert(70%)"
          }} src={item.pmImg} alt={item.pmName}/>
      </Card.Section>

      <Group position="apart" mt="md">
        <div>
          <Text fw={800}>{item.pmName}</Text>
          <Text fz="xs" c="dimmed">
            {item.pmRequirements}
          </Text>
        </div>
        <Badge color='#4772fa' variant="outline">Beta</Badge>
      </Group>

      <Card.Section className={classes.section} mt="md">
        {/* <Text fz="sm" c="dimmed" className={classes.label}>
          Basic configuration
        </Text>

        <Group spacing={8} mb={-8}>
          {features}
        </Group> */}
        <Group spacing={30}>
        <Button onClick={()=>window.open(item.pmLink)} radius="xl" style={{ flex: 1, backgroundColor:"#4772fa" }}>
            Download
          </Button>
        </Group>
       
      </Card.Section>

      {/* <Card.Section className={classes.section}>
        <Group spacing={30}>
          <div>
            <Text fz="xl" fw={700} sx={{ lineHeight: 1 }}>
              $168.00
            </Text>
            <Text fz="sm" c="dimmed" fw={500} sx={{ lineHeight: 1 }} mt={3}>
              per day
            </Text>
          </div>

          <Button radius="xl" style={{ flex: 1 }}>
            Rent now
          </Button>
        </Group>
      </Card.Section> */}
    </Card>
  );
}