import {
    createStyles,
    Card,
    Text,
    SimpleGrid,
    UnstyledButton,
    Anchor,
    Group,
    rem,
    Container,
    Flex,
} from '@mantine/core';
import { useEffect, useState } from 'react';

const useStyles = createStyles((theme) => ({

    inner: {
        // display: 'flex',
        justifyContent: 'space-between',
        paddingTop: `calc(${theme.spacing.xl}) * 4`,
        paddingBottom: `calc(${theme.spacing.xl} * 4)`,
        // height: '80vh',
    },

    content: {
        // maxWidth: rem(480),
        // marginRight: `calc(${theme.spacing.xl} * 3)`,

        [theme.fn.smallerThan('md')]: {
            maxWidth: '100%',
            marginRight: 0,
        },
    },

    card: {
        width: 200,
        justifyContent: 'center',
        textAlign: 'center',
        paddingTop: 20,
        paddingBottom: 20,
        backgroundColor: theme.colorScheme === 'dark' ? "#1A1B1E" : theme.colors.gray[0],
        boxShadow:"inset rgb(8 11 14 / 37%) 2px 9px 20px 20px, inset rgb(0 0 0 / 10%) 0px 10px 20px 0px",
    },

    title: {
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        fontWeight: 700,
    },

    item: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        borderRadius: theme.radius.md,
        height: rem(90),
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
        transition: 'box-shadow 150ms ease, transform 100ms ease',

        '&:hover': {
            boxShadow: theme.shadows.md,
            transform: 'scale(1.05)',
        },
    },
}));

const StatCard = ({ title, data }: any) => {
    const { classes } = useStyles();
    const [stat, setstat] = useState(0)

    useEffect(() => {
        if (data==0) return;


        if(data==stat) return

        const interval = setTimeout(() => {
            setstat(stat => stat + 1)
        }, 5000/data);

        return () => clearTimeout(interval);

    }, [data,stat])

    return (
        <Card radius="md" className={classes.card}>
            <Text style={{
                fontWeight: 700,
            }} size={40}>
                {stat}
            </Text>

            <Text size="xl" mt={7}>
                {title}
            </Text>
        </Card>
    );
}

export function ActionsGrid() {
    const { classes, theme } = useStyles();
    const [stats, setStats] = useState({
        users: 0,
        notes: 0,
        nists: 0
    })

    useEffect(() => {
        fetch('https://ticknask-backend-ten.vercel.app/space/stats')
            .then(response => response.json())
            .then(data => setStats(data.stats));
    }, [])

    return (
        <Container mt={140}>
            <div className={classes.inner}>
                <div className={classes.content}>
                    <Flex
                        mih={50}
                        bg="#3c3c3c54"
                        gap={100}
                        justify="center"
                        align="center"
                        direction="row"
                        wrap="wrap"
                        style={{
                            backgroundColor: "#25262b",
                            padding: 40,
                            boxShadow:"rgb(8 11 14 / 6%) 0px 0px 1px 0px, rgb(8 11 14 / 10%) 0px 16px 16px -1px",
                            borderRadius: 10,
                        }}
                    >

                        <StatCard title="Users" data={stats.users} />
                        <StatCard title="Notes" data={stats.notes} />
                        <StatCard title="Nists" data={stats.nists} />

                    </Flex>
                </div>
            </div>
        </Container>
    );
}